<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-row>
          <b-col cols="1"> </b-col>
          <b-col cols="10">
            <h1>{{ $t("DOCUMENTATION_IMPORT.WORK") }}</h1>
            <br />
            <br />
            <br />
            <h2>{{ $t("DOCUMENTATION_IMPORT.IMPORT") }}</h2>
            <div class="h2-div">
              <br />
              <ul>
                <li>
                  <p>
                    <strong>{{ $t("DOCUMENTATION_IMPORT.IMPORT_LI1") }}</strong>
                    <br />
                    <span
                      >{{ $t("DOCUMENTATION_IMPORT.IMPORT_EXAMPLE1") }}
                      <a download :href="fileExampleUrl"
                        >import_example.csv</a
                      ></span
                    ><br />
                    <!-- <span
                      >{{ $t("DOCUMENTATION_IMPORT.IMPORT_EXAMPLE2") }}
                      <a download :href="docUrl">ips.pdf</a></span
                    > -->
                  </p>
                </li>

                <li>
                  <p>
                    <strong>{{ $t("DOCUMENTATION_IMPORT.IMPORT_LI2") }}</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      {{ $t("DOCUMENTATION_IMPORT.IMPORT_LI3") }}
                      <router-link to="devices">{{
                        $t("DOCUMENTATION_IMPORT.IMPORT")
                      }}</router-link>
                    </strong>
                  </p>
                </li>
              </ul>
              <br />
            </div>
            <br />
            <br />
            <!--
            <h2 v-if="$i18n.locale == 'ru'">
              {{ $t("DOCUMENTATION_IMPORT.SOKOL") }}
            </h2>
            <div class="h2-div" v-if="$i18n.locale == 'ru'">
              <br />
              <ol>
                <li>
                  <p>
                    {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI11") }}
                    <a href="http://sokolmeteo.com">http://sokolmeteo.com</a>
                    {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI12") }}
                  </p>
                </li>
                <li>
                  <p>
                    {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI2") }}
                  </p>
                </li>
                <li>
                  <p>
                    {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI3") }}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI4") }}
                    </strong>
                  </p>
                  <ol class="m-5">
                    <li>
                      <p>{{ $t("DOCUMENTATION_IMPORT.SOKOL_LI41") }}</p>
                    </li>
                    <li>
                      <p>{{ $t("DOCUMENTATION_IMPORT.SOKOL_LI42") }}</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <strong>
                      {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI5") }}
                    </strong>
                  </p>
                  <ol class="m-5">
                    <li>
                      <p>
                        {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI51")
                        }}<a href="http://sokolmeteo.com"
                          >http://sokolmeteo.com</a
                        >
                      </p>
                    </li>
                    <li>
                      <p>{{ $t("DOCUMENTATION_IMPORT.SOKOL_LI52") }}</p>
                    </li>
                    <li>
                      <p>{{ $t("DOCUMENTATION_IMPORT.SOKOL_LI53") }}</p>
                    </li>
                    <li>
                      <p>
                        {{ $t("DOCUMENTATION_IMPORT.SOKOL_LI54") }}
                        <router-link to="devices">
                          {{
                            $t("DOCUMENTATION_IMPORT.SOKOL_DEVICES_LINK")
                          }}</router-link
                        >
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
              <br />
            </div>
            -->
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../core/services/store/breadcrumbs.module";

export default {
  name: "Api",
  data() {
    return {
      showOverlay: true,
    };
  },
  async created() {
    this.$nextTick(function () {
      this.showOverlay = false;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DOCUMENTATION_IMPORT") },
    ]);
  },
  computed: {
    fileExampleUrl() {
      return process.env.BASE_URL + "media/files/import_example.csv";
    },
    docUrl() {
      const lang = this.$i18n.locale;
      if (lang === "ru") {
        return process.env.BASE_URL + "media/files/ips_ru.pdf";
      } else {
        return process.env.BASE_URL + "media/files/ips_en.pdf";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.h2-div {
  margin-left: 2%;
  margin-right: 2%;
}
</style>
